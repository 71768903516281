import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Container as InnerContainer } from "common";
import ProgressBar from "react-bootstrap/ProgressBar";
import styles from "./Offers.module.scss";
import { OfferCard, Button, ToolTip, HeaderWL, WhiteFooter, HelpButton, Confetti, Container as InternalContainer } from "common";
import { SelectDropdown } from "common/form";
import _ from "lodash";
import strings from "localization/Strings";
import { sortingMetrics } from "utils/constants";
import { offersTracking, useOffers } from "./Offers.hooks";
import { formatAmount } from "utils/formatters";

const Offers: React.FC = () => {
  const { handleContinueToOffers, showAll, scrollToTop, getOfferLabel, showDisclaimer, whitelabel, offersState, offersConstants, borrower, navigate, application } = useOffers();
  const { wentToOffers, offersMatched, offersSearchingText, selectedSortOption, progressStatus, allOffers, offersLoaded, offerLowestAPRId, offerHighestLoanAmountId, setSelectedSortOption } = offersState;
  const { offerColors, text3 } = offersConstants;
  const { handleSelectOffer, handleAdvertiserDisclosureHover, handleHelpButtonClick, handlePartnerGuaranteeDisclosureHover, handleRepresentativeExampleHover, handlePrivacyPolicyClick, handleTermsOfServiceClick } = offersTracking();

  return (
    <>
      <HeaderWL instructions={wentToOffers ? "Congratulations! We've matched you with the offers below!" : null} />
      <main className={styles.offersContainer}>
        <Container fluid className={styles.helpContainer}>
          <Row>
            <Col className={styles.helpCol1}>
              <HelpButton type="BORROWER" onClick={handleHelpButtonClick} />
            </Col>
            <Col className={styles.helpCol2}>
              <ToolTip
                textToolTip="Advertiser Disclosure"
                text={strings.advertiserDisclosure.replaceAll("{whitelabelName}", whitelabel?.name)}
                placement="bottom"
                onHover={handleAdvertiserDisclosureHover}
              />
            </Col>
          </Row>
        </Container>
        <>
          {
            offersMatched === "WAITING" && !wentToOffers && <>
              <InnerContainer className={styles.offerInfoContainerAnimation}>
                <div className={styles.offerInnerContainer}>
                  <div className={styles.searchingOffersAnimation} />
                  <p id="offers_titleSearching" className={styles.titleSearching}>{offersSearchingText}</p>
                </div>
              </InnerContainer>
              <div>
                <div className={styles.offerInnerContainer}>
                  {showDisclaimer()}
                </div>
              </div>
            </>
          }
          {
            offersMatched === "TRUE" && !wentToOffers &&
            <Confetti
              displayShadow
              isPopup={false}
              buttonText={(borrower?.lender_type === "DTC" || borrower?.source === "EMAIL") ? "Continue to offers" : "See how much you prequalify"}
              buttonAction={handleContinueToOffers}>
              {(borrower?.lender_type === "DTC" || borrower?.source === "EMAIL") && <>
                Amazing news!
                <br />
                We've matched you with some offers!
              </>}
              {borrower?.lender_type === "DTM" && borrower?.source === "WEB" && <>
                Amazing news!
                <br />
                You have prequalified with
                <br />
                one of our lenders!
              </>}
            </Confetti>
          }
          {
            offersMatched === "FALSE" && !wentToOffers && <>
              <InnerContainer>
                <div className={styles.offerInnerContainer}>
                  <p className={styles.title} style={{ color: "var(--primaryVariationTextColor)", margin: "2.5rem 0" }}>{text3}</p>
                </div>
              </InnerContainer>
              <div>
                <div className={styles.offerInnerContainer}>
                  {showDisclaimer()}
                </div>
              </div>
            </>
          }
          {
            wentToOffers && (borrower?.lender_type === "DTC" || application?.status === "Offered" || application?.status === "Clicked" || application?.status === "Funded" || application?.status === "Application Submitted" && application?.source === "EMAIL") && <div className={styles.offerInnerContainer}>
              <h1>Your offers</h1>
              <div className={styles.sortBlock} style={{ display: "flex", justifyContent: "flex-end" }}>
                <SelectDropdown
                  id="offers_yourOffers"
                  name="sortOffers"
                  value={selectedSortOption}
                  placeholder="Sort your offers"
                  selectOptions={sortingMetrics}
                  onChange={(value: any) => { setSelectedSortOption(value) }}
                  insideTable={true}
                  minWidth={268}// based on Offer amount: highest to lowest
                  boldTextBeforeColon={true}
                />
              </div>
              {(progressStatus < 100) &&
                <ProgressBar
                  now={progressStatus}
                  className={styles.progressBar}
                />}
              <div className={styles.offerList}>
                {allOffers.map((data: any, i: any) => (
                  <OfferCard
                    {...data}
                    id={`offerCard_select_${i}`}
                    label={allOffers.length > 1 ? getOfferLabel(data, i) : ""}
                    labelColor={(i < offerColors.length && offerLowestAPRId != offerHighestLoanAmountId || i == 0 && offerLowestAPRId != offerHighestLoanAmountId) ? offerColors[i] : ""}
                    offerLogo={data.lender_logo_url}
                    key={i}
                    offerInfoVariant={false}
                    onClick={handleSelectOffer}
                  />
                ))}
              </div>
              {allOffers.length < offersLoaded.length ? (
                <div className={styles.offerBtnContainer}>
                  <Button
                    id="offers_showMore"
                    type="button"
                    label="Show 5 more"
                    variant="secondary"
                  />
                  <Button
                    id="offers_showAll"
                    type="button"
                    label="Show all"
                    onClick={showAll}
                  />
                </div>
              ) : (
                <div className={styles.offerBtnContainer}>
                  <Button
                    id="offers_scrollTop"
                    type="button"
                    label="Scroll to Top"
                    onClick={scrollToTop}
                  />
                </div>
              )}
              {showDisclaimer()}
            </div>}
          {/* DTM Prequalify screen */}
          {wentToOffers && borrower?.lender_type === "DTM" && (application?.status === "Pending with Merchant" || application?.status === "Application Submitted") && application?.source === "WEB" && <>
            <div className={styles.invitationFormContainer}>
              <InternalContainer className={styles.invitationFormInner}>
                <div className={styles.brandLogo}>
                  {/* correct might be just logo_url - don't know which one is correct */}
                  <img src={allOffers[0]?.lender_logo_url} alt="lender logo" />
                </div>
                <p className={styles.titleDtm}>
                  {`You are prequalified for up to $${formatAmount(allOffers[0]?.amount?.toString())} with ${allOffers[0]?.lender_name}`}
                </p>
                <OfferCard
                  {...allOffers[0]}
                  id={`offerCard_dtm`}
                  label=""
                  labelColor=""
                  offerLogo={allOffers[0]?.lender_logo_url}
                  offerInfoVariant={false}
                  dtmPrequalify={borrower?.lender_type === "DTM"}
                />
                <h3 className={styles.nextSteps}>The final amount will be selected by the merchant within the next 24 hours. You will receive your offers via email and can then select an offer and finalize it with the lender.</h3>
              </InternalContainer>
            </div>
          </>
          }
          {/* {
            // the conditions are most likely redundant
            offersMatched === "WAITING" && !wentToOffers &&
            <div style={{ width: "100%" }}>
              <LoadingBar styleOverride={{ borderRadius: "20px", width: "90%", maxWidth: "814px", left: "calc((100% - min(814px, 90%)) / 2)", top: "100px" }} />
            </div>
          } */}
        </>
      </main >
      {borrower?.lender_type === "DTM" && application?.status === "Pending with Merchant" && (offersMatched === "FALSE" || wentToOffers) &&
        <p className={styles.offersDisclaimer}>If you did not receive an offer from a specific Lending Partner, they may not have been able to determine whether you qualified for a personal loan based on the information you provided. No credit decision has been made. You may still qualify for a personal loan from our partners.</p>
      }
      <WhiteFooter onPartnerGuaranteeDisclosureHover={handlePartnerGuaranteeDisclosureHover} onPrivacyPolicyClick={handlePrivacyPolicyClick} onRepresentativeExampleHover={handleRepresentativeExampleHover} onTermsOfServiceClick={handleTermsOfServiceClick} />
    </>
  );
};

export default Offers;
